@tailwind base;
@tailwind components;
@tailwind utilities;

.ag-row .ag-cell {
  display: flex;
  align-items: center;
}

.fc table {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 0.85em;
}

.fc .fc-toolbar-title {
  font-size: 1.25em;
  margin: 0;
}

.fc .fc-button {
  padding: 0.3em 0.45em;
  font-size: 0.8em;
}
.signature-canvas {
  /*pointer-events: none;*/
  height: 100%;
  width: 100%;
}

.signature {
  border: 1px solid black;
  padding: 5px;
  border-radius: 5px;
  width: 100%;
}

.signature-box{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;
}

#signature-branch,#signature-admin{
  width: 40%;
  height: 300px;
}
#header-table-delivery{
  padding:10px 20px;
  display: flex;
}
#box-qrcode-delivery-form{
  display: flex;
  justify-content: center;
}
